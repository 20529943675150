<template>
  <v-container>
    <v-card v-if="books && books.length > 0">
      <v-toolbar dense color="" class="elevation-0">
        <v-toolbar-title v-if="title">
          <router-link
            v-if="sort && sort != 'library'"
            :to="{
              name: 'CategoryShow',
              params: { slug: 'all' },
              query: { sort: sort },
            }"
            >{{ title }}</router-link
          >
          <router-link
            v-else-if="sort && sort == 'library'"
            :to="{
              name: 'AccountLibrary',
            }"
            >{{ title }}</router-link
          >
          <div v-else>{{ title }}</div>
        </v-toolbar-title>
      </v-toolbar>
      <div class="py-2" :class="isMobile ? 'px-1' : 'px-4'">
        <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
          <swiper-slide
            v-for="(item, index) in books"
            :key="index"
            :index="index"
          >
            <book-small-card :item="item"></book-small-card>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        </swiper>
        <div
          v-if="!isMobile"
          class="myswiper-button-prev"
          @click="prev"
          slot="button-prev"
        ></div>
        <div
          v-if="!isMobile"
          class="myswiper-button-next"
          @click="next"
          slot="button-next"
        ></div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import { Navigation, Pagination } from 'swiper';
import "swiper/swiper-bundle.css";
// import 'swiper/css/swiper.css'
// Swiper.use([Navigation, Pagination]);
import { mapGetters } from "vuex";

import BookSmallCard from "./BookSmallCard.vue";
export default {
  components: { BookSmallCard, Swiper, SwiperSlide },
  // directives: { swiper: directive },
  data: () => ({
    swiperOptions: {
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      //   type: 'progressbar',
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      slidesPerView: 1,
      spaceBetween: 0,
      // init: false,

      breakpoints: {
        320: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 6,
        },
        1300: {
          slidesPerView: 7,
        },
      },
      // Some Swiper option/callback...
    },
  }),
  methods: {
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
  props: ["title", "books", "sort"],
  computed: {
    ...mapGetters(["currentUser", "loadPage", "books_top"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
